import TagBadge from 'components/Badge/TagBadge'
import { find } from 'lodash'
import { useEffect, useMemo, useState, isValidElement } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import CreatableSelect from 'react-select/creatable'
import { components } from 'react-select'
import { Option } from './Option'
import globalMessages from 'components/globalMessages'
import labelMessages from 'components/labelMessages'
import { Icon, Text } from 'components/Primitives'
import { ACCENT_COLORS_LIST } from 'constants/index'
import { pickTextColorBasedOnBgColor } from 'utils'
import classNames from 'classnames'
import s from './TextSelect.module.scss'
import { useDebounce } from 'ahooks'

export const customClassNames = {
  control: (state) => classNames(state.selectProps.hasError && s.hasError, state.selectProps.disabled && s.disabled, s.control, s[state.selectProps.type], state.selectProps.customClasses?.control),
  container: (state) => classNames(s.container, s[state.selectProps.type]),
  valueContainer: (state) => classNames(s.valueContainer, s[state.selectProps.type]),
  singleValue: (state) => classNames(s.singleValue, s[state.selectProps.type]),
  menu: (state) => classNames(s.menu, s[state.selectProps.type])
}

export const customStyles = {
  container: (provided, state) => ({
    ...provided,
    minWidth: state.selectProps.isMulti ? '0' : 'max-content',
    pointerEvents: state.selectProps.disabled ? 'none' : 'all',
    cursor: state.selectProps.disabled ? 'not-allowed' : 'pointer'
  }),
  control: (provided, state) => ({
    ...provided,
    ...(state.selectProps.size == 'sm'
      ? {
          minHeight: '36px',
          height: '36px'
        }
      : {
          minHeight: '44px',
          height: 'auto'
        }
    ),
    boxShadow: 'none'
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    ...(state.selectProps.size == 'sm' ? { height: '36px' } : undefined)
  }),
  menuPortal: (provided) => ({
    ...provided,
    zIndex: 9999,
    pointerEvents: 'all'
  }),
  menu: (provided) => ({
    ...provided,
    marginTop: '4px',
    boxShadow: '0px 0px 16px rgba(0, 0, 0, 0.16)'
  }),
  menuList: (provided) => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
    borderRadius: '0.25rem'
  }),
  singleValue: (provided) => ({
    ...provided,
    marginLeft: '4px',
    marginRight: '4px',
    overflow: 'visible'
  }),
  placeholder: (provided) => ({
    ...provided,
    marginLeft: '4px',
    marginRight: '4px'
  }),
  multiValue: (provided) => ({
    ...provided,
    background: 'transparent'
  })
}

const ValueContainer = (props) => (
  <div role='valueContainer' className='flex-1'>
    <components.ValueContainer {...props} />
  </div>
)

const SingleValue = (props) => {
  const { selectProps, children, data } = props
  return (
    selectProps.asTags
      ? (
        <components.SingleValue {...props}>
          <TagBadge
            noTruncate
            noTooltip
            hex={data.color}
            id={data.id}
          >
            {data.label || data.name || children}
          </TagBadge>
        </components.SingleValue>
        )
      : <components.SingleValue className={s.singleValue} {...props} />
  )
}

const MultiValueLabel = (props) => {
  const { children, data } = props
  return (
    <TagBadge
      withAppendix
      noTooltip
      hex={data.color}
      id={data.id}
    >
      {data.label || data.name || children}
    </TagBadge>
  )
}
const MultiValueRemove = (props) => {
  const { data, innerProps } = props
  const accentColor = find(ACCENT_COLORS_LIST, { bg: data.color })
  const fontColor = accentColor ? accentColor.fg : pickTextColorBasedOnBgColor(data.color || '#b5bcc2')

  const initialStyle = { backgroundColor: data.color, color: fontColor }
  const [style, setStyle] = useState(initialStyle)

  const onMouseEnter = () => setStyle({ backgroundColor: fontColor, color: '#fff' })
  const onMouseLeave = () => setStyle(initialStyle)

  useEffect(() => {
    setStyle(initialStyle)
  }, [data])

  return (
    <button
      data-action='remove-badge'
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      {...innerProps}
      style={style}
      className={classNames(innerProps.className, s.multiValueRemove)}
    >
      <Icon name='Close' size='xs' />
    </button>
  )
}

const IndicatorSeparator = () => null

const DropdownIndicator = (props) => {
  const open = props.selectProps.menuIsOpen
  return !props.selectProps.noDropdownIcon && (
    <components.DropdownIndicator {...props}>
      <Icon name={props.selectProps.dropdownIcon || 'ChevronDown'} size={(props.selectProps.dropdownIcon && 's') || undefined} className={classNames(s.dropdownIndicator, open && !props.selectProps.noIconAnimation && s.dropdownIndicatorOpen)} />
    </components.DropdownIndicator>
  )
}

const ClearIndicator = (props) => (
  <components.ClearIndicator {...props}>
    <Icon name='CloseFilled' size='xxs' />
  </components.ClearIndicator>
)

/**
 * Modern type-search dropdown with create abilities.
 */
// Use the menuPortalTarget with caution, now it doesn't block the scroll (thanks to overlay wrapping in Dialog primitive). But it disables collision detection and will stay in bottom menuPlacement even if it's out of the screen.
//
// `placement` can be "auto" (default), "bottom", or "top"
export const TextSelect = ({ options, className, wrapperClassName, customClasses, placeholder, noOptionsMessage, value, onChange, isGroup, asTags, isMulti, creatable = true, name, allowClear = true, isSearchable = true, required, hasError, size = 'm', disabled, type = 'standard', dropdownIcon, noIconAnimation, noDropdownIcon, createNewMessage, role, id, onOpenListener, placement, ...otherProps }) => {
  asTags = asTags || isMulti
  const intl = useIntl()

  const optionsAsString = useMemo(() => {
    const o = options && options[0]
    if (!o) return true
    return (typeof (o) === 'string')
  }, [options])

  const processedOptions = useMemo(() => {
    if (!options) return []
    if (optionsAsString) {
      return options.map(o => o && o.trim() !== '' ? ({ label: o, value: o, color: '#f3f5f7' }) : null).filter(i => i)
    } else {
      return options.map(o => {
        return ({
          ...o,
          color: o.color || '#f3f5f7',
          label: typeof o.label === 'string' || isValidElement(o.label)
            ? o.label
            : intl.formatMessage(o.label)
        })
      })
    }
  }, [options])

  const internalValue = useMemo(() => {
    if (isMulti) {
      return value
        ? value.map(v => {
            let val = processedOptions.filter(o => o.value == v)[0]
            if (!val && v && (typeof v !== 'string' || (typeof v === 'string' && v.trim() != ''))) {
              val = { label: v, value: v, color: '#f3f5f7' }
            }
            return val
          })
        : []
    } else {
      let val = null
      if (value && (typeof value !== 'string' || (typeof value === 'string' && value.trim() != ''))) {
        if (typeof value === 'object') val = value
        else {
          val = processedOptions.filter(o => o.value == value)[0]
          if (!val) {
            val = { label: value, value: value, color: '#f3f5f7' }
          }
        }
      }
      return required ? val || processedOptions[0] : val
    }
  }, [value, processedOptions])

  const handleChange = (newValue) => {
    if (isMulti) {
      onChange(newValue.map(v => v.value))
    } else {
      onChange(newValue?.value)
    }
  }

  useEffect(() => {
    if (required && !value) {
      handleChange(processedOptions[0])
    }
  }, [])

  const [open, setOpen] = useState(false)
  const debouncedOpen = useDebounce(open, 1)
  useEffect(() => {
    if (onOpenListener) onOpenListener(open)
  }, [open])

  return (
    <div className={wrapperClassName} data-disabled={!!disabled} data-role={role}>
      <CreatableSelect
        onMenuOpen={() => setOpen(true)}
        onMenuClose={() => setOpen(false)}
        name={name}
        className={classNames(s.select, className)}
        isMulti={isMulti}
        closeMenuOnSelect={!isMulti}
        isClearable={allowClear}
        isSearchable={isSearchable}
        required={required}
        onChange={handleChange}
        options={processedOptions}
        value={internalValue}
        isValidNewOption={(inputValue, _, selectOptions) => {
          if (!creatable) return false
          return inputValue.trim() !== '' && !selectOptions.map(o => o.value).includes(inputValue.trim())
        }}
        id={id ? `textSelect_${id}` : otherProps['data-name'] ? `textSelect_${otherProps['data-name']}` : undefined}
        placeholder={
          <Text color='gray' bold={type == 'standalone'}>
            {placeholder || (isGroup
              ? <FormattedMessage {...(creatable ? labelMessages.searchOrCreateGroup : labelMessages.searchGroup)} />
              : <FormattedMessage {...(creatable ? labelMessages.searchOrCreateOption : labelMessages.searchOption)} />
            )}
          </Text>
        }
        noOptionsMessage={() => (
          <Text size='s'>{noOptionsMessage || (isGroup
            ? <FormattedMessage {...globalMessages.noGroups} />
            : <FormattedMessage id='control.noOptions' defaultMessage='No options available.' />
          )}
          </Text>
        )}
        components={{
          Option,
          ValueContainer,
          SingleValue,
          MultiValueLabel,
          MultiValueRemove,
          IndicatorSeparator,
          DropdownIndicator,
          ClearIndicator
        }}
        theme={(theme) => ({
          ...theme,
          borderRadius: '0.25rem',
          colors: {
            ...theme.colors,
            primary: '#4094da',
            primary75: '#c1e3ff',
            primary50: '#c1e3ff',
            primary25: '#eef7ff',
            danger: '#da1e28',
            dangerLight: '#fed7d4'
          }
        })}
        menuPlacement={placement || 'auto'}
        classNames={customClassNames}
        styles={customStyles}
        {...otherProps}
        // Passed to the components
        asTags={asTags}
        size={size}
        hasError={hasError}
        disabled={disabled}
        type={type}
        dropdownIcon={dropdownIcon}
        noIconAnimation={noIconAnimation}
        noDropdownIcon={noDropdownIcon}
        customClasses={customClasses}
        createNewMessage={createNewMessage}
        minMenuHeight={debouncedOpen ? 285 : 0}
        maxMenuHeight={280}
      />
    </div>
  )
}
