import { CronPicker } from 'components/Pickers'

export const CronEditor = ({ value, onValueChange, stopEditing }) => {
  const onOpenChange = (open) => {
    if (!open) stopEditing()
  }

  return (
    <CronPicker
      defaultOpen
      value={value}
      onChange={(val) => {
        onValueChange(val)
        stopEditing()
      }}
      noIcon
      onOpenChange={onOpenChange}
    />
  )
}
