import { usePrevious } from 'ahooks'
import { useEffect, useMemo, useRef, useState } from 'react'

export const useLoadingPerformance = (fetchStatus) => {
  // fetchStatus can either be the fetchStatus string from tanstack-query or a boolean
  const isFetching = useMemo(() => {
    return typeof (fetchStatus) === 'string' ? fetchStatus === 'fetching' : fetchStatus
  }, [fetchStatus])
  const [loadingTime, setLoadingTime] = useState(null)
  const startTimeRef = useRef(null)

  const prevIsFetching = usePrevious(isFetching)
  useEffect(() => {
    if (!prevIsFetching && isFetching) {
      startTimeRef.current = performance.now()
    }
  }, [fetchStatus])

  useEffect(() => {
    if (prevIsFetching && !isFetching && startTimeRef.current) {
      const endTime = performance.now()
      setLoadingTime(endTime - startTimeRef.current)
    }
  }, [fetchStatus])

  return loadingTime
}
