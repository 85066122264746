import { Button } from 'components/Button'
import { Checkbox, Popover, RangeCalendar, Tooltip } from 'components/Primitives'
import { useEffect, useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { getRangePreset } from 'utils'
import { calculateFloatingRange, formatISODate, formatLocalized, formatLocalizedRange, rangeToFloating } from 'utils/datetime'
import { CUSTOM_RANGE, DFNS_LONG_DATE_FORMAT, FLOATING_RANGE } from 'constants/index'
import { isSameDay, parseISO } from 'date-fns'
import labelMessages from 'components/labelMessages'
import { usePrevious } from 'ahooks'
import { FloatingPeriodPicker, formatFloatingPeriod } from './Partials/FloatingPeriodPicker'
import { TextSelect } from 'components/TextSelect'
import Heading from 'components/Heading'
import { isEqual, startsWith } from 'lodash'
import { useIsMobile } from 'hooks'
import MobileFriendlyModal from 'components/MobileFriendlyModal'
import globalMessages from 'components/globalMessages'
import classNames from 'classnames'
import { FilterFooter } from 'components/FilterFooter'
import { RangePreview } from 'components/RangePreview'
import { useComparingPeriodStore } from 'hooks/store/useComparingPeriodStore'
import messages from './messages'

export const RangePicker = ({ onChange, value: range, option: rangeOption, placeholder, weekly, prefixPlaceholder, presets, allowReset, type, disabled, comparisonPeriodPickerKey }) => {
  const intl = useIntl()
  const [open, setOpen] = useState(false)
  const isMobile = useIsMobile()

  const presetOptions = useMemo(() => presets.map(p => ({ key: p, ...getRangePreset(p) })), [presets])

  const option = getRangePreset(rangeOption)
  const value = useMemo(() => {
    if (rangeOption === FLOATING_RANGE) {
      return calculateFloatingRange(range) || []
    }
    if (!option) {
      return []
    }
    if (rangeOption !== CUSTOM_RANGE && !!option) {
      return option.value.map((item) => formatISODate(item))
    }
    return range
  }, [range, rangeOption])
  const date = value.map((item) => typeof (item) === 'string' ? parseISO(item) : item)
  const hasValue = !!date.length

  const foundPreset = useMemo(() => {
    const d = date
    // if the picker is resetted, we return the unsetPreset
    if (!rangeOption || (rangeOption === CUSTOM_RANGE && value.length === 0) || (rangeOption === FLOATING_RANGE && !calculateFloatingRange(range))) {
      return null
    } else if (rangeOption === FLOATING_RANGE || rangeOption === CUSTOM_RANGE) { // we have a selection and not a preset
      return null
    }
    return presetOptions.find(
      (option) =>
        d.length &&
      option.value.length &&
      isSameDay(d[0], option.value[0]) &&
      isSameDay(d[1], option.value[1])
    )
  }, [value, presetOptions])

  const stateDefaults = {
    view: foundPreset ? 'default' : rangeOption === FLOATING_RANGE ? FLOATING_RANGE : CUSTOM_RANGE,
    selectedPreset: foundPreset ? foundPreset.key : (!rangeOption ? CUSTOM_RANGE : rangeOption),
    selectedRange: foundPreset ? foundPreset.value : date,
    floatingRangeValue: rangeToFloating(range)
  }

  const [view, setView] = useState(stateDefaults.view)
  const [selectedPreset, setSelectedPreset] = useState(stateDefaults.selectedPreset)
  const [selectedRange, setSelectedRange] = useState(stateDefaults.selectedRange)
  const [floatingRangeValue, setFloatingRangeValue] = useState(stateDefaults.floatingRangeValue)

  const setCurrentDate = useComparingPeriodStore(state => state.setCurrentDate)

  // Controlling comparison period
  useEffect(() => {
    if (!open && startsWith(comparisonPeriodPickerKey, 'templates')) return
    setCurrentDate(
      comparisonPeriodPickerKey,
      {
        value: view === FLOATING_RANGE ? floatingRangeValue : selectedRange,
        option: selectedPreset
      }
    )
  }, [selectedRange, selectedPreset, view, floatingRangeValue])

  const [applied, setApplied] = useState(false)
  const resetDefaults = () => {
    if (applied) {
      setApplied(false)
      return
    }
    setView(stateDefaults.view)
    setSelectedPreset(stateDefaults.selectedPreset)
    setSelectedRange(stateDefaults.selectedRange)
    setFloatingRangeValue(stateDefaults.floatingRangeValue)
  }

  // to be controlled from outside
  useEffect(() => {
    // I removed the `if (range && rangeOption)` condition, otherwise the rendered placeholder would not update when the picker is resetted
    resetDefaults()
  }, [range, rangeOption])

  const apply = () => {
    const oldValue = [range, rangeOption]
    let newValue
    switch (view) {
      case CUSTOM_RANGE:
        newValue = [selectedRange.map(d => formatISODate(d)), CUSTOM_RANGE]
        break
      case FLOATING_RANGE:
        if (!calculateFloatingRange(floatingRangeValue)) return // failsafe to prevent invalid settings
        newValue = [floatingRangeValue, FLOATING_RANGE]
        break
      default:
        newValue = [selectedRange.map(d => formatISODate(d)), selectedPreset]
        break
    }

    if (onChange && !isEqual(oldValue, newValue)) {
      onChange(newValue[0], newValue[1])
    }
  }

  // Reset defaults when closing the popover
  const previousOpen = usePrevious(open)
  useEffect(() => {
    if (previousOpen && !open) resetDefaults()
  }, [open, isMobile, previousOpen])

  const renderRangeText = () => {
    if (selectedPreset === FLOATING_RANGE) {
      const m = formatFloatingPeriod(floatingRangeValue, intl)
      return (intl.locale === 'en-US') ? m.toLowerCase() : m
    }

    if (selectedPreset === CUSTOM_RANGE && selectedRange.length === 0) {
      return intl.formatMessage(labelMessages.dateRange)
    }

    const selectedOption = getRangePreset(selectedPreset)
    let selectedDate
    if (!selectedOption) {
      selectedDate = []
    } else if (selectedPreset !== CUSTOM_RANGE && !!selectedOption) {
      selectedDate = selectedOption.value.map((item) => formatISODate(item))
    } else {
      selectedDate = selectedRange
    }

    let foundSelectedPreset
    if (selectedPreset !== CUSTOM_RANGE) {
      foundSelectedPreset = presetOptions.find(
        (option) =>
          selectedDate.length &&
        option.value.length &&
        isSameDay(selectedDate[0], option.value[0]) &&
        isSameDay(selectedDate[1], option.value[1])
      )
      if (foundSelectedPreset) return intl.formatMessage(foundSelectedPreset.label, foundSelectedPreset.params)
    }

    if (!weekly) {
      return formatLocalizedRange(selectedDate, intl)
    }

    return selectedDate
      .map((item, i) =>
        formatLocalized(item,
          i === 0
            ? `'${intl.formatMessage(labelMessages.weekNumber)}'I' | '${DFNS_LONG_DATE_FORMAT}`
            : DFNS_LONG_DATE_FORMAT,
          intl
        )
      )
      .join(' - ')
  }

  useEffect(() => {
    const newFloating = rangeToFloating(selectedRange)
    if (!isEqual(floatingRangeValue, newFloating)) setFloatingRangeValue(newFloating)
  }, [selectedRange])

  const onTimeRangeSelect = (key) => {
    switch (key) {
      case CUSTOM_RANGE:
      case FLOATING_RANGE:
        setSelectedPreset(key)
        setView(key)
        break
      default:
        setSelectedPreset(key)
        const preset = presetOptions.find(o => o.key === key)
        if (preset) {
          setSelectedRange(preset.value)
          setView('default')
        }
        break
    }
  }

  const reset = () => {
    onChange([], CUSTOM_RANGE)
    setOpen(false)
  }

  const [textSelectOpen, setTextSelectOpen] = useState(false)

  const renderContent = () => {
    return (
      <>
        <div>
          <div className='mb-4 flex justify-between'>
            <Heading type='h5'>
              <FormattedMessage {...messages.dateRangeFilter} />
            </Heading>
            {allowReset && <Button size='s' name='btn-reset' type='tertiary' className='px-0 bg-transparent' disabled={!hasValue} onClick={reset}>{intl.formatMessage(globalMessages.reset)}</Button>}
          </div>
          <TextSelect
            id='timeRange'
            className='w-full'
            value={[CUSTOM_RANGE, FLOATING_RANGE].includes(selectedPreset) ? CUSTOM_RANGE : selectedPreset}
            onChange={onTimeRangeSelect}
            creatable={false}
            allowClear={false}
            options={[
              { value: CUSTOM_RANGE, label: intl.formatMessage(messages.customDateRange) },
              ...presetOptions.map(o => ({ value: o.key, label: intl.formatMessage(o.label) }))
            ]}
            onOpenListener={setTextSelectOpen}
            placement='bottom'
          />
          {view === CUSTOM_RANGE && <RangeCalendar className='mt-4' onChange={setSelectedRange} value={selectedRange.length === 0 ? null : selectedRange} />}
          {view === FLOATING_RANGE && <FloatingPeriodPicker className='mt-4 mb-2' onChange={setFloatingRangeValue} value={floatingRangeValue} />}
          {(view === CUSTOM_RANGE || view === FLOATING_RANGE) && (
            <Tooltip title={intl.formatMessage(messages.rollingDateRangeTooltip)}>
              <Checkbox checked={selectedPreset === FLOATING_RANGE} onCheckedChange={v => onTimeRangeSelect(v ? FLOATING_RANGE : CUSTOM_RANGE)} label={intl.formatMessage(messages.rollingDateRange)} data-cy='rolling-checkbox' />
            </Tooltip>
          )}
        </div>
        {view !== CUSTOM_RANGE && view !== FLOATING_RANGE && (
          <div className='flex flex-col'>
            <RangePreview className='mt-4' range={selectedRange} />
          </div>
        )}
      </>
    )
  }

  const onApplyButtonClick = () => {
    apply()
    setOpen(false)
    setApplied(true)
  }

  const onCancelButtonClick = () => {
    setOpen(false)
  }

  const buttonDisabled = useMemo(() => {
    let shouldDisable = false
    if (view === CUSTOM_RANGE) {
      shouldDisable = shouldDisable || selectedRange.length !== 2
    }
    if (view === FLOATING_RANGE) {
      shouldDisable = shouldDisable || !calculateFloatingRange(floatingRangeValue)
    }
    return shouldDisable
  }, [selectedRange, floatingRangeValue, view])

  const renderTrigger = () => {
    return (
      <Button
        icon='Calendar'
        type='secondary'
        data-role='range-picker'
        pure
        whiteHoverBg
        className={classNames('mb-2 mr-2', {
          'hover:shadow-grid': !disabled,
          'bg-gray-lighter': type === 'templates' && !disabled,
          ' h-7': type === 'templates',
          'bg-white': type !== 'templates' && !disabled,
          'h-9': type !== 'templates',
          'text-gray-light': !open && !hasValue && !applied,
          'bg-disabled cursor-default text-gray-light': disabled
        })}
        onClick={() => setOpen(!open)}
        disabled={disabled}
      >
        {(open || hasValue || applied) && placeholder && prefixPlaceholder
          ? `${placeholder}: ${renderRangeText()}`
          : renderRangeText()}
      </Button>
    )
  }

  if (isMobile) {
    return (
      <>
        {renderTrigger()}
        {open && (
          <MobileFriendlyModal
            visible={open}
            title={intl.formatMessage(labelMessages.timeRange)}
            onCancel={onCancelButtonClick}
            onPrimary={onApplyButtonClick}
            footer={{
              primaryText: intl.formatMessage(globalMessages.ok)
            }}
          >
            {renderContent()}
          </MobileFriendlyModal>
        )}
      </>
    )
  }
  return (
    <Popover
      open={open}
      onOpenChange={setOpen}
      trigger={renderTrigger()}
      inModal={type === 'templates'}
    >
      {(maxHeight) => (
        <div className={classNames('flex flex-col p-4 min-w-72', !textSelectOpen && 'overflow-auto')} style={{ maxHeight }}>
          {renderContent()}
          <FilterFooter onCancel={onCancelButtonClick} onSubmit={onApplyButtonClick} submitDisabled={buttonDisabled} />
        </div>
      )}
    </Popover>
  )
}
