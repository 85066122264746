import classNames from 'classnames'
import { useIntl } from 'react-intl'
import { formatLocalizedRange } from 'utils/datetime'
import { isAfter } from 'date-fns'
import { Text } from 'components/Primitives'

export const RangePreview = ({ range, isValid = true, className, message }) => {
  const intl = useIntl()

  if (range && isAfter(range[0], range[1])) range = [range[1], range[0]]

  return (
    <Text type='filter' size='m' className={classNames(className, 'text-center desktop:max-w-64', !isValid && 'text-feedback-error')}>
      {range && range.length ? formatLocalizedRange(range, intl) : '...'}
      {!isValid && message && (
        <>
          <br />
          {message}
        </>
      )}
    </Text>
  )
}
