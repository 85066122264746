import { FormattedMessage, useIntl } from 'react-intl'
import { find, isEqual, pick, omit } from 'lodash'
import { useDebounce, useUnmount } from 'ahooks'
import { useEffect, useRef, useState } from 'react'

import CreatableSelect from 'react-select/creatable'
import { DeleteConfirmation } from './DeleteConfirmation'
import { MultiValueRemove } from './MultiValueRemove'
import { Option } from './Option'
import TagBadge from 'components/Badge/TagBadge'
import TagForm from './TagForm'
import classNames from 'classnames'
import { getRandomColor } from 'components/Badge'
import globalMessages from 'components/globalMessages'
import message from 'services/message'
import s from './Tags.module.scss'
import { useAdminData } from 'hooks'
import labelMessages from 'components/labelMessages'
import { Text } from 'components/Primitives'
import { components } from 'react-select'

const MultiValueLabel = (props) => (
  <TagBadge
    className='leading-normal'
    withAppendix
    editable={!props.selectProps.asFormField}
    onAction={props.selectProps.onMenuAction ? (action) => props.selectProps.onMenuAction(action, props.data) : undefined}
    hex={props.data.color}
    noTooltip
    id={props.data.id}
  >
    {props.data.name}
  </TagBadge>
)

const ValueContainer = ({ ...props }) => <components.ValueContainer className='value_container_selector' {...props} />

export const TagPicker = ({ onSelectionChanged, type, initialSelection, onShouldCancelEditMode, asFormField, menuPortalTarget, style, ...props }) => {
  const intl = useIntl()
  const [nextRandomColor, setNextRandomColor] = useState(getRandomColor())
  const { data, add, update, remove } = useAdminData('tags', { enabled: true, type })

  const [selected, setSelected] = useState(initialSelection || [])
  const [editTag, setEditTag] = useState(null)
  const [deleteTag, setDeleteTag] = useState(null)
  const ref = useRef()

  const onMenuAction = (action, data) => {
    switch (action) {
      case 'edit':
        setEditTag(data)
        break
      case 'delete':
        setDeleteTag(data)
        break
    }
  }

  const setSelectedTags = (tags) => {
    setSelected(tags)
    if (asFormField) {
      onSelectionChanged(tags)
    }
  }

  const debouncedSelected = useDebounce(selected, { wait: 300 })

  useEffect(() => {
    // we don't want direct updates in inline editing (this would try to assign the tag before it was created)
    if (asFormField) return
    if (data.items && onSelectionChanged) {
      onSelectionChanged(debouncedSelected)
    }
  }, [debouncedSelected])

  // since we are debouncing changes, we have to make sure they are triggered when unmounting before the debounce timeout was reached
  useUnmount(() => {
    if (!asFormField) return
    if (!isEqual(selected, debouncedSelected)) {
      onSelectionChanged(selected)
    }
  })

  // Update the selected tags when the collection of tags changes.
  // This is necessary to catch changing or deleting of already selected tags
  useEffect(() => {
    if (data && data.items) {
      const cleanedSelection = selected
        .map(s => {
          const existing = find(data.items, { id: s.id })
          if (existing) {
            return existing
          } else {
            return null
          }
        })
        .filter(i => i)
      if (!isEqual(cleanedSelection, selected)) {
        setSelected(cleanedSelection)
      }
    }
  }, [data.items])

  const onFormSubmit = (values) => {
    update.mutateAsync({
      id: editTag.id,
      ...values
    })
      .then((res) => {
        setEditTag(null)
        data.refetch()
        // after action and the modal is closed we should focus the field again
        if (ref.current && ref.current.inputRef) {
          window.setTimeout(() => ref.current.inputRef.focus(), 300)
        }
      })
      .catch((err) => {
        message.error(intl.formatMessage(globalMessages.actionFailed, { status: err.status, message: err.message }))
      })
  }
  const onTagDelete = () => {
    remove.mutateAsync(deleteTag.id)
      .then((res) => {
        setDeleteTag(null)
        data.refetch()
        // after action and the modal is closed we should focus the field again
        if (ref.current && ref.current.inputRef) {
          window.setTimeout(() => ref.current.inputRef.focus(), 300)
        }
      })
      .catch((err) => {
        message.error(intl.formatMessage(globalMessages.actionFailed, { status: err.status, message: err.message }))
      })
  }

  const onTagCreate = (name) => {
    const color = nextRandomColor
    // Temporary adding a draft item which will be replaced after the mutation
    /* setSelectedTags([...selected, {
      id: -1,
      name,
      color
    }]) */
    setNextRandomColor(getRandomColor())
    add.mutateAsync({
      name,
      color
    })
      .then((res) => {
        data.refetch().then(() => {
          setSelectedTags([...selected.filter(i => i.id !== -1), res])
        })
        // trackEvent('Tag created', { ID: res.id, name })
      })
      .catch((err) => {
        message.error(intl.formatMessage(globalMessages.actionFailed, { status: err.status, message: err.message }))
      })
  }

  return (
    <div className={s.wrapper} data-type='creatable-select' {...pick(props, ['data-name', 'name', 'data-type'])} style={style}>
      <CreatableSelect
        ref={ref}
        autoFocus={!asFormField}
        isLoading={data.isLoading}
        isDisabled={data.status === 'error'}
        isClearable={false}
        className={classNames('min-w-16', s.select)}
        isMulti
        options={data.items || []}
        value={selected}
        onChange={setSelectedTags}
        onBlur={(e) => {
          // we should not canceling the edit mode if we are bluring because a modal window opened or dropdown open
          const parent = e.relatedTarget && e.relatedTarget.parentElement
          if (!(parent && (parent.classList.contains('modal-footer') || parent.getAttribute('role') === 'alertdialog'))) {
            onShouldCancelEditMode()
          }
        }}
        onMenuAction={onMenuAction}
        components={{ Option, MultiValueLabel, MultiValueRemove, ValueContainer }}
        placeholder={<span className={asFormField ? 'text-base' : 'text-sm'}><FormattedMessage {...labelMessages.searchOrCreateTag} /></span>}
        noOptionsMessage={() => (
          <Text size='s'><FormattedMessage {...globalMessages.noGroups} /></Text>
        )}
        getOptionValue={(option) => String(option.id)}
        getOptionLabel={(option) => option.name}
        onCreateOption={onTagCreate}
        menuPlacement='auto'
        defaultMenuIsOpen={!asFormField}
        menuIsOpen={asFormField ? undefined : true}
        asFormField={asFormField}
        getNewOptionData={(inputValue, optionLabel) =>
          ({
            draft: true,
            id: optionLabel.toString(),
            name: inputValue,
            color: nextRandomColor
          })}
        theme={(theme) => ({
          ...theme,
          borderRadius: '0.25rem',
          colors: {
            ...theme.colors,
            primary: '#4094da',
            primary75: '#c1e3ff',
            primary50: '#c1e3ff',
            primary25: '#eef7ff',
            danger: '#da1e28',
            dangerLight: '#fed7d4'
          }
        })}
        styles={{
          control: (provided, state) => ({
            ...omit(provided, ['boxShadow']),
            ...(asFormField ? { height: '44px' } : undefined)
          }),
          multiValue: (provided) => ({
            ...provided,
            backgroundColor: 'transparent'
          })
        }}
        menuPortalTarget={menuPortalTarget}
      />

      <TagForm
        visible={editTag != null}
        onCancel={() => setEditTag(null)}
        onSubmit={onFormSubmit}
        title={<FormattedMessage id='tags.editTag' defaultMessage='Edit Tag' />}
        initialValues={editTag}
        onDelete={onTagDelete}
        confirmLoading={update.isPending || remove.isPending}
      />

      <DeleteConfirmation tag={deleteTag} visible={deleteTag != null} onCancel={() => setDeleteTag(null)} onConfirm={onTagDelete} />
    </div>
  )
}
