import { SearchSelect } from 'components/SearchSelect'
import useDemo from 'hooks/useDemo'
import { useIntl } from 'react-intl'
import { useLocations } from 'hooks'
import { useItemCategory } from 'hooks/queries/useItemCategory'
import labelMessages from 'components/labelMessages'
import { useAmplitudePickerLoadingPerformance } from 'components/AppCore/Amplitude'

export default ({ categoryNo, from, withPortal, type, value, onChange, location, range, gray, disabled, onValidationChange }) => {
  const intl = useIntl()
  const { demo } = useDemo()

  // TODO: check how this is working, useLocations doesn't return an array
  const allLocations = useLocations()
  const locations = Array.isArray(location) ? !(!location.length || location.length === allLocations.length) ? location : null : [location]
  const { data, status, isFetching, isPending, fetchStatus } = useItemCategory({
    from,
    categoryNo,
    range,
    location: locations
  })

  useAmplitudePickerLoadingPerformance(`Item Group ${categoryNo}`, fetchStatus, { Locations: locations, 'Date Range': range })

  if ((isPending && fetchStatus === 'idle') || (status === 'success' && data && data.length === 0)) {
    return null
  }

  return (
    <SearchSelect
      role={`item-category-${categoryNo}-picker`}
      type={type || 'round'}
      size='small'
      placeholder={intl.formatMessage(labelMessages.itemGroup, { no: categoryNo })}
      value={value}
      onChange={onChange}
      onValidationChange={onValidationChange}
      loading={isFetching}
      optionsLabel={intl.formatMessage(labelMessages.groups)}
      options={data && data.map((str) => ({
        key: str,
        label: demo(str)
      }))}
      graybg={gray}
      withPortal={withPortal}
      disabled={disabled}
    />
  )
}
