import { divide100, getConfigDialogTitle, getPatchValues, multiply100, isEmpty } from 'utils'
import { defaultTo, get, omit, pickBy } from 'lodash'
import { GenericForm } from 'components/Form'
import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { batchRoundingSchema, fulfillmentDelaySchema, multidayTrackingSchema, orderEnabledSchema, orderFactorSchema, quantileSchema, referenceItemSchema } from './validationSchema'
import labelMessages from 'components/labelMessages'
import { OVERPLAN_TYPES } from 'constants/index'
import { getConfigFields } from 'components/ConfigFieldsAndColumns/Fields'
import formMessages from 'components/formMessages'

const getFormSpecificOverplanValue = (initialValues) => {
  if (!initialValues) return null

  const overplanType = defaultTo(get(initialValues, 'overplan_type', null), null)
  if (overplanType === null) return null

  const baseValue = defaultTo(get(initialValues, 'overplan_value', null), null)
  if (baseValue === null) return null

  switch (overplanType) {
    default:
      return baseValue
    case OVERPLAN_TYPES.ABSOLUTE_VALUE:
      return parseFloat(baseValue)
    case OVERPLAN_TYPES.QUANTILE:
    case OVERPLAN_TYPES.SALES_PERCENTAGE:
    case OVERPLAN_TYPES.ORDER_PERCENTAGE:
      return parseFloat(baseValue) * 100
  }
}

const getRealOverplanValue = (values) => {
  const baseValue = values.overplan_value

  if (values.overplan_type == null || values.overplan_type === '') return null
  if (baseValue === null) return null

  switch (values.overplan_type) {
    case OVERPLAN_TYPES.ABSOLUTE_VALUE:
      return baseValue
    case OVERPLAN_TYPES.QUANTILE:
    case OVERPLAN_TYPES.SALES_PERCENTAGE:
    case OVERPLAN_TYPES.ORDER_PERCENTAGE:
      return baseValue / 100
  }
}

const getConfigFieldKeysByTableType = (tableType) => {
  switch (tableType) {
    case 'quantile':
    default:
      return ['overplan_type', 'overplan_value_1']
    case 'fulfillmentDelay':
      return ['fulfillment_delay']
    case 'orderFactor':
      return ['order_factor']
    case 'ordersEnabled':
      return ['orders_enabled']
    case 'referenceItem':
      return ['reference_item']
    case 'batchRounding':
      return ['stochastic_rounding', 'batch_rounding_cutoff', 'min_amount_rounding_cutoff']
    case 'multidayTracking':
      return ['multiday_tracking', 'inventory_tracking']
  }
}

const getValidationSchema = (tableType) => {
  switch (tableType) {
    case 'quantile':
      return quantileSchema
    case 'fulfillmentDelay':
      return fulfillmentDelaySchema
    case 'orderFactor':
      return orderFactorSchema
    case 'ordersEnabled':
      return orderEnabledSchema
    case 'referenceItem':
      return referenceItemSchema
    case 'batchRounding':
      return batchRoundingSchema
    case 'multidayTracking':
      return multidayTrackingSchema
  }
}

const OrderConfigForm = ({ visible, onCancel, initialValues, onSubmit, bulkEditingCount, confirmLoading, tableType, duplicate }) => {
  const bulkEditing = bulkEditingCount != null
  const intl = useIntl()

  const validationSchema = useMemo(() => getValidationSchema(tableType), [tableType])

  const initValues = bulkEditing
    ? {}
    : {
        itemOrGroup: defaultTo(get(initialValues, 'item_tag', ''), '') === '' ? 'item' : 'group',
        rank: defaultTo(get(initialValues, 'rank', ''), ''),
        item: defaultTo(get(initialValues, 'item', ''), ''),
        item_tag: defaultTo(get(initialValues, 'item_tag', ''), ''),
        locationOrGroup: defaultTo(get(initialValues, 'location_tag', ''), '') === '' ? 'location' : 'group',
        location: defaultTo(get(initialValues, 'location', ''), ''),
        location_tag: defaultTo(get(initialValues, 'location_tag', ''), ''),
        // This is needed for nullability check linear ticket https://linear.app/delicious-data/issue/TEC-216/enable-nullable-fields-on-order-and-todo-config-tables
        // overplan_type: defaultTo(get(initialValues, 'overplan_type', ''), ''),
        overplan_type: defaultTo(get(initialValues, 'overplan_type', ''), ''),
        overplan_value: getFormSpecificOverplanValue(initialValues),
        date: initialValues && initialValues.from_date
          ? [initialValues.from_date, initialValues.to_date]
          : [],
        from_date: defaultTo(get(initialValues, 'from_date', null), null),
        to_date: defaultTo(get(initialValues, 'to_date', null), null),
        weekdays: defaultTo(get(initialValues, 'weekdays', []), []).map(i => i.toString()), // AntSelect needs strings :(
        order_factor: multiply100(defaultTo(get(initialValues, 'order_factor', null), null)),
        fulfillment_delay: defaultTo(get(initialValues, 'fulfillment_delay', null), null),
        orders_enabled: defaultTo(get(initialValues, 'orders_enabled', null), null),
        multiday_tracking: defaultTo(get(initialValues, 'multiday_tracking', null), null),
        inventory_tracking: defaultTo(get(initialValues, 'inventory_tracking', null), null),
        reference_item: defaultTo(get(initialValues, 'reference_item', null), null),
        batch_rounding_cutoff: multiply100(defaultTo(get(initialValues, 'batch_rounding_cutoff', null), null)),
        min_amount_rounding_cutoff: multiply100(defaultTo(get(initialValues, 'min_amount_rounding_cutoff', null), null)),
        stochastic_rounding: defaultTo(get(initialValues, 'stochastic_rounding', null), null)
      }

  const handleSubmit = (values) => {
    const initValuesForSubmit = initValues && {
      ...initValues,
      item: initValues.itemOrGroup === 'item' ? initValues.item : null,
      item_tag: initValues.itemOrGroup === 'group' ? initValues.item_tag : null,
      location: initValues.locationOrGroup === 'location' ? initValues.location : null,
      location_tag: initValues.locationOrGroup === 'group' ? initValues.location_tag : null,
      overplan_value: getRealOverplanValue(initValues),
      order_factor: divide100(initValues.order_factor),
      fulfillment_delay: initValues.fulfillment_delay < 0 ? null : initValues.fulfillment_delay,
      orders_enabled: initValues.orders_enabled === '-' ? null : initValues.orders_enabled,
      multiday_tracking: initValues.multiday_tracking === '-' ? null : initValues.multiday_tracking,
      inventory_tracking: initValues.inventory_tracking === '-' ? null : initValues.inventory_tracking,
      batch_rounding_cutoff: initValues.stochastic_rounding ? null : divide100(initValues.batch_rounding_cutoff),
      min_amount_rounding_cutoff: initValues.stochastic_rounding ? null : divide100(initValues.min_amount_rounding_cutoff),
      stochastic_rounding: initValues.stochastic_rounding === '-' ? null : initValues.stochastic_rounding
    }
    const modifiedValues = {
      ...values,
      overplan_value: getRealOverplanValue(values),
      item: values.itemOrGroup === 'item' ? values.item : null,
      item_tag: values.itemOrGroup === 'group' ? values.item_tag : null,
      location: values.locationOrGroup === 'location' ? values.location : null,
      location_tag: values.locationOrGroup === 'group' ? values.location_tag : null,
      ...(values.date && values.date.length === 2 ? { from_date: values.date[0], to_date: values.date[1] } : { from_date: null, to_date: null }),
      order_factor: divide100(values.order_factor),
      fulfillment_delay: values.fulfillment_delay < 0 ? null : values.fulfillment_delay,
      orders_enabled: values.orders_enabled === '-' ? null : values.orders_enabled,
      multiday_tracking: values.multiday_tracking === '-' ? null : values.multiday_tracking,
      inventory_tracking: values.inventory_tracking === '-' ? null : values.inventory_tracking,
      // no special rule needed for stochastic_rounding, since if that's enabled we disable all other kinds of rounding
      batch_rounding_cutoff: values.stochastic_rounding ? null : divide100(values.batch_rounding_cutoff),
      min_amount_rounding_cutoff: values.stochastic_rounding ? null : divide100(values.min_amount_rounding_cutoff)
    }

    const bulkEditValues = {
      ...values,
      ...(values.overplan_value !== undefined ? { overplan_value: getRealOverplanValue(values) || 0 } : {}),
      ...(values.itemOrGroup === 'item' ? { item: values.item, item_tag: null } : {}),
      ...(values.itemOrGroup === 'group' ? { item_tag: values.item_tag, item: null } : {}),
      ...(values.locationOrGroup === 'location' ? { location: values.location, location_tag: null } : {}),
      ...(values.locationOrGroup === 'group' ? { location_tag: values.location_tag, location: null } : {}),
      ...(values.date !== undefined ? values.date.length === 2 ? { from_date: values.date[0], to_date: values.date[1] } : { from_date: null, to_date: null } : {}),
      // Make sure no invalid settings are set (would "delete" the configs)
      ...(values.order_factor !== undefined ? { order_factor: divide100(values.order_factor) || 0 } : {}),
      ...(values.fulfillment_delay !== undefined ? { fulfillment_delay: values.fulfillment_delay < 0 ? 0 : values.fulfillment_delay || 0 } : {}),
      ...(values.orders_enabled !== undefined ? { orders_enabled: values.orders_enabled === '-' ? false : values.orders_enabled || false } : {}),
      ...(values.multiday_tracking !== undefined ? { multiday_tracking: values.multiday_tracking === '-' ? false : values.multiday_tracking || false } : {}),
      ...(values.inventory_tracking !== undefined ? { inventory_tracking: values.inventory_tracking === '-' ? false : values.inventory_tracking || false } : {}),
      ...(values.batch_rounding_cutoff !== undefined ? { batch_rounding_cutoff: divide100(values.batch_rounding_cutoff) || 0 } : {}),
      ...(values.min_amount_rounding_cutoff !== undefined ? { min_amount_rounding_cutoff: divide100(values.min_amount_rounding_cutoff) || 0 } : {}),
      ...(values.stochastic_rounding !== undefined ? { stochastic_rounding: values.stochastic_rounding || false } : {}),
      ...(!values.reference_item || values.reference_item === '' ? { reference_item: undefined } : { reference_item: values.reference_item })
    }

    let submitValues = bulkEditing
      ? omit(bulkEditValues, Object.keys(bulkEditValues).filter((key) => bulkEditValues[key] === undefined))
      : duplicate
        ? modifiedValues
        : getPatchValues(initValuesForSubmit, modifiedValues, [
          'rank',
          'item',
          'item_tag',
          'location',
          'location_tag',
          'overplan_type',
          'overplan_value',
          'from_date',
          'to_date',
          'weekdays',
          'order_factor',
          'fulfillment_delay',
          'orders_enabled',
          'multiday_tracking',
          'inventory_tracking',
          'reference_item',
          'batch_rounding_cutoff',
          'min_amount_rounding_cutoff',
          'stochastic_rounding'
        ])

    // when duplicating, all fields are somehow set, mostly with empty strings.
    // we only want to submit fields which are not nulls, empty strings or empty arrays
    if (duplicate) {
      submitValues = pickBy(submitValues, (v) => (Array.isArray(v) && v.length > 0) || !isEmpty(v))
    }
    if (onSubmit) {
      onSubmit(submitValues)
    }
  }

  const structure = [{
    title: intl.formatMessage(labelMessages.limitingFactors),
    fields: [
      {
        type: 'number-format',
        decimalSeparator: ',',
        decimalScale: 0,
        label: intl.formatMessage(labelMessages.rank),
        name: 'rank'
      },
      {
        type: 'text-select',
        name: 'itemOrGroup',
        label: intl.formatMessage(labelMessages.item),
        required: !bulkEditing,
        allowClear: false,
        creatable: false,
        options: [
          { value: 'item', label: intl.formatMessage(labelMessages.limitByArticle) },
          { value: 'group', label: intl.formatMessage(labelMessages.limitByItemTag) }
        ]
      },
      {
        type: 'item',
        variant: 'single',
        name: 'item',
        visible: (values) => values.itemOrGroup === 'item'
      },
      {
        type: 'search-tag',
        variant: 'single',
        name: 'item_tag',
        tagType: 'item',
        visible: (values) => values.itemOrGroup === 'group',
        placeholder: intl.formatMessage(labelMessages.selectTag)
      },
      {
        type: 'text-select',
        name: 'locationOrGroup',
        label: intl.formatMessage(labelMessages.locations),
        required: !bulkEditing,
        allowClear: false,
        creatable: false,
        options: [
          { value: 'location', label: intl.formatMessage(labelMessages.limitByLocation) },
          { value: 'group', label: intl.formatMessage(labelMessages.limitByLocationTag) }
        ]
      },
      {
        type: 'location',
        variant: 'single',
        name: 'location',
        visible: (values) => values.locationOrGroup === 'location'
      },
      {
        type: 'search-tag',
        variant: 'single',
        name: 'location_tag',
        tagType: 'saleslocation',
        visible: (values) => values.locationOrGroup === 'group',
        placeholder: intl.formatMessage(labelMessages.selectTag)
      },
      {
        type: 'daterange',
        label: intl.formatMessage(labelMessages.dateRangeOptional),
        name: 'date',
        allowReset: true
      },
      {
        type: 'weekday',
        label: intl.formatMessage(labelMessages.weekdaysOptional),
        name: 'weekdays',
        variant: 'multi',
        withHoliday: true,
        placeholder: intl.formatMessage(formMessages.selectWeekdays)
      }]
  },
  {
    title: intl.formatMessage(labelMessages.configuration),
    fields: getConfigFields(getConfigFieldKeysByTableType(tableType), intl, bulkEditing)
  }]

  return (
    <GenericForm
      asModal
      title={getConfigDialogTitle(intl, 'order', tableType, initialValues != null && !duplicate)}
      visible={visible}
      structure={structure}
      validationSchema={bulkEditing ? undefined : validationSchema}
      initialValues={initValues}
      onSubmit={handleSubmit}
      onCancel={onCancel}
      confirmLoading={confirmLoading}
      bulkEditing={bulkEditing}
      bulkEditingCount={bulkEditingCount}
    />
  )
}

export default OrderConfigForm
