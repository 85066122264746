import { OVERPLAN_TYPES } from 'constants/index'
import * as yup from 'yup'

export const quantileSchema = () =>
  yup.object().shape({
    rank: yup.number().required(),
    item: yup.number().nullable(),
    item_tag: yup.string().nullable(),
    location: yup.number().nullable(),
    overplan_type: yup.string().oneOf([OVERPLAN_TYPES.QUANTILE, OVERPLAN_TYPES.ABSOLUTE_VALUE, OVERPLAN_TYPES.SALES_PERCENTAGE, OVERPLAN_TYPES.ORDER_PERCENTAGE]).required(),
    overplan_value: yup.mixed().test('overplan_type', 'Invalid Overplan Value', function (value) {
      const { overplan_type } = this.parent
      switch (overplan_type) {
        case OVERPLAN_TYPES.ORDER_PERCENTAGE:
          return typeof value === 'number' && value >= 0 && value <= 50
        case OVERPLAN_TYPES.QUANTILE:
          return typeof value === 'number' && value >= 0 && value <= 100
        default:
          return typeof value === 'number'
      }
    })
  })

export const fulfillmentDelaySchema = () =>
  yup.object().shape({
    rank: yup.number().required(),
    item: yup.number().nullable(),
    item_tag: yup.string().nullable(),
    location: yup.number().nullable(),
    fulfillment_delay: yup.number()
  })

export const orderFactorSchema = () =>
  yup.object().shape({
    rank: yup.number().required(),
    item: yup.number().nullable(),
    item_tag: yup.string().nullable(),
    location: yup.number().nullable(),
    order_factor: yup.number()
  })

export const batchRoundingSchema = () =>
  yup.object().shape({
    rank: yup.number().required(),
    item: yup.number().nullable(),
    item_tag: yup.string().nullable(),
    location: yup.number().nullable(),
    batch_rounding_cutoff: yup.number()
      .min(0)
      .max(100)
      .nullable()
      .when('stochastic_rounding', {
        is: true,
        then: schema => schema.nullable(),
        otherwise: schema => schema.required()
      }),
    stochastic_rounding: yup.boolean()
  })

export const orderEnabledSchema = () =>
  yup.object().shape({
    rank: yup.number().required(),
    item: yup.number().nullable(),
    item_tag: yup.string().nullable(),
    location: yup.number().nullable(),
    orders_enabled: yup.boolean()
  })

export const multidayTrackingSchema = () =>
  yup.object().shape({
    rank: yup.number().required(),
    item: yup.number().nullable(),
    item_tag: yup.string().nullable(),
    location: yup.number().nullable(),
    multiday_tracking: yup.boolean(),
    inventory_tracking: yup.boolean()
  })

export const referenceItemSchema = () =>
  yup.object().shape({
    rank: yup.number().required(),
    item: yup.number().nullable(),
    item_tag: yup.string().nullable(),
    location: yup.number().nullable(),
    reference_item: yup.string().required()
  })
