import { SalesLocation } from 'components/SalesLocation'
import { Article } from 'components/Article'
import { OVERPLAN_TYPES, OVERPLAN_TYPES_MESSAGES, TODO_ORDER_CONFIG_COLUMN_TITLES } from 'constants/index'
import { find } from 'lodash'
import { Badge } from 'components/Primitives'
import globalMessages from 'components/globalMessages'
import { TagBadgeNew } from 'components/Badge/TagBadgeNew'
import { Grid } from 'components/Grid'
import { baseNumberFormatter, percentFormatter } from 'components/Grid/formatter'

const getColumn = (key, locationTagData, itemTagData, intl, tiny) => {
  let col = {
    headerName: intl.formatMessage(TODO_ORDER_CONFIG_COLUMN_TITLES[key]),
    sortable: false
  }

  switch (key) {
    case 'rank':
      col = {
        ...col,
        field: 'rank',
        type: Grid.TYPE.INTEGER,
        editable: !tiny,
        sortable: true
      }
      break
    case 'itemOrGroup':
      col = {
        ...col,
        field: 'item',
        type: Grid.TYPE.COMPONENT,
        disguise: true,
        renderer: (params) => {
          if (params.data.item) return <Article showRemotePK id={params.value} />
          if (params.data.item_tag) {
            if (!itemTagData || !itemTagData.items) return '...'
            const tag = find(itemTagData.items, { id: parseInt(params.data.item_tag, 10) })
            if (!tag) {
              return (
                <Badge
                  size='s'
                  icon='WarningAltFilled'
                  iconPosition='right'
                  type='accentNeutral'
                  tooltip={intl.formatMessage(globalMessages.tagNotFound)}
                >Undefined
                </Badge>
              )
            }
            return <TagBadgeNew hex={tag.color} references={tag.objects_count} assignType='item'>{tag.name}</TagBadgeNew>
          }
          return null
        }
      }
      break
    case 'locationOrGroup':
      col = {
        ...col,
        field: 'location',
        type: Grid.TYPE.COMPONENT,
        disguise: true,
        renderer: (params) => {
          if (params.data.location) return <SalesLocation id={params.value} />
          if (params.data.location_tag) {
            if (!locationTagData || !locationTagData.items) return '...'
            const tag = find(locationTagData.items, { id: parseInt(params.data.location_tag, 10) })
            if (!tag) {
              return (
                <Badge
                  size='s'
                  icon='WarningAltFilled'
                  iconPosition='right'
                  type='accentNeutral'
                  tooltip={intl.formatMessage(globalMessages.tagNotFound)}
                >Undefined
                </Badge>
              )
            }
            return <TagBadgeNew hex={tag.color} references={tag.objects_count} assignType='saleslocation'>{tag.name}</TagBadgeNew>
          }
          return null
        }
      }
      break
    case 'dateRange':
      col = {
        ...col,
        field: 'from_date',
        type: Grid.TYPE.DATERANGE,
        fromField: 'from_date',
        toField: 'to_date'
      }
      break
    case 'timeRange':
      col = {
        ...col,
        field: 'from_time',
        type: Grid.TYPE.TIMERANGE,
        fromField: 'from_time',
        toField: 'to_time'
      }
      break
    case 'weekdays':
      col = {
        ...col,
        field: 'weekdays',
        type: Grid.TYPE.WEEKDAYS
      }
      break
    case 'daily_quantile' :
      col = {
        ...col,
        field: 'daily_quantile',
        disguise: true,
        type: Grid.TYPE.PERCENT,
        editable: !tiny
      }
      break
    case 'scheduled_due_by':
    case 'scheduled_last_until':
      col = {
        ...col,
        field: key,
        disguise: true,
        type: Grid.TYPE.TEXT,
        editable: !tiny
      }
      break
    case 'lead_interval':
    case 'sustain_interval':
      col = {
        ...col,
        field: key,
        type: Grid.TYPE.INTEGER,
        editable: !tiny
      }
      break
    case 'lead_quantile':
    case 'sustain_quantile':
      col = {
        ...col,
        field: key,
        type: Grid.TYPE.PERCENT,
        editable: !tiny
      }
      break
    case 'inventory_group_enabled':
    case 'orders_enabled':
    case 'multiday_tracking':
    case 'inventory_tracking':
      col = {
        ...col,
        field: key,
        type: Grid.TYPE.BOOLEAN,
        variant: 'activeinactive'
      }
      break
    case 'overplan_type':
      col = {
        ...col,
        field: 'overplan_type',
        type: Grid.TYPE.CUSTOMFORMATTER,
        valueFormatter: (params) => params.value != null && OVERPLAN_TYPES_MESSAGES[params.value] ? intl.formatMessage(OVERPLAN_TYPES_MESSAGES[params.value]) : ''
      }
      break
    case 'overplan_value':
      col = {
        ...col,
        field: 'overplan_value',
        type: Grid.TYPE.CUSTOMFORMATTER,
        valueFormatter: (params) => {
          switch (params.data.overplan_type) {
            case OVERPLAN_TYPES.ABSOLUTE_VALUE:
              return baseNumberFormatter(params)
            case OVERPLAN_TYPES.QUANTILE:
            case OVERPLAN_TYPES.SALES_PERCENTAGE:
            case OVERPLAN_TYPES.ORDER_PERCENTAGE:
              return percentFormatter(params)
          }
        }
      }
      break
    case 'fulfillment_delay':
      col = {
        ...col,
        field: 'fulfillment_delay',
        type: Grid.TYPE.DAYS,
        editable: !tiny
      }
      break
    case 'order_factor':
      col = {
        ...col,
        field: 'order_factor',
        type: Grid.TYPE.PERCENT,
        editable: !tiny
      }
      break
    case 'reference_item':
      col = {
        ...col,
        field: 'reference_item',
        type: Grid.TYPE.COMPONENT,
        disguise: true,
        renderer: (params) => params.value != null && <Article showRemotePK id={params.value} />
      }
      break
    case 'batch_rounding_cutoff':
    case 'min_amount_rounding_cutoff':
      col = {
        ...col,
        field: key,
        type: Grid.TYPE.PERCENT,
        editable: !tiny
      }
      break
    case 'stochastic_rounding':
      col = {
        ...col,
        field: 'stochastic_rounding',
        type: Grid.TYPE.BOOLEAN,
        variant: 'activeinactive',
        fallbackValue: false
      }
      break
  }

  return col
}

export const getColumns = (keys, locationTagData, itemTagData, intl, tiny) =>
  keys.map((key) => getColumn(key, locationTagData, itemTagData, intl, tiny))
