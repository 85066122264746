import { Filter } from 'hooks/useAvailableFilters'
import { find, forEach, forOwn, groupBy, intersection, isPlainObject, map, zipObject } from 'lodash'
import { ensureUniqueValues, getOrdering } from 'utils'

const convertColDefToBackendSortString = (colDef, replaceDict = {}) =>
`${colDef.sort === 'asc' ? '' : '-'}${replaceDict[colDef.colId] ? replaceDict[colDef.colId] : colDef.colId}`

export const getOrderingForSortModel = (sortModel = [], replaceDict = {}) =>
  sortModel.map(sm => convertColDefToBackendSortString(sm, replaceDict))

export const convertDataQueryParamsToLegacyParams = (pageSettings, params, overwrite, overwriteCombineFilters, combineFilters = true, orderId = true, sortKeysReplacements = {}, defaultSorter = ['id']) => {
  const locFilters = find(params, { field: 'sales_location' })
  // FIXME: We can't safely filter for og strings right now // TODO: Why not?
  // const og1Filters = find(params, { field: 'menuline' })
  // const og2Filters = find(params, { field: 'component' })

  const cFilters = {
    // Fallback to default if an empty date range was saved
    ...(pageSettings[Filter.DATE_RANGE]?.value ? combineFilters ? { date_range: pageSettings[Filter.DATE_RANGE].value } : { date_range: pageSettings[Filter.DATE_RANGE].value.join(',') } : undefined),
    ...(locFilters
      ? { sales_locations: Array.isArray(locFilters.value) ? locFilters : [locFilters.value] }
      : undefined),
    ...overwriteCombineFilters
    // FIXME: We can't safely filter for og strings right now // TODO: Why not?
    // ...(og1Filters ? { menuline_items: og1Filters.value } : undefined),
    // ...(og2Filters ? { component_items: og2Filters.value } : undefined)
  }

  let ordering
  if (pageSettings.sortModel) {
    const sortModel = getOrderingForSortModel(pageSettings.sortModel, sortKeysReplacements)
    forEach(defaultSorter, s => {
      if (intersection([`-${s}`, s], sortModel).length === 0) sortModel.push(s)
    })
    ordering = sortModel.join(',')
  } else {
    ordering = orderId
      ? getOrdering(pageSettings.sorter, sortKeysReplacements) ? `${getOrdering(pageSettings.sorter, sortKeysReplacements)},category,sales_location` : 'category,sales_location'
      : `${getOrdering(pageSettings.sorter, sortKeysReplacements)},category,sales_location` || 'category,sales_location'
  }

  return {
    ordering,
    page: pageSettings.pagination && pageSettings.pagination.current ? pageSettings.pagination.current : 1,
    page_size: pageSettings.pagination && pageSettings.pagination.pageSize ? pageSettings.pagination.pageSize : 100,
    ...(pageSettings.search && pageSettings.search.trim() !== '' ? { search: pageSettings.search } : undefined),
    ...(pageSettings.wasteCategory && pageSettings.wasteCategory.length > 0 ? { categories: pageSettings.wasteCategory.join(',') } : undefined),
    ...(combineFilters ? { combine_filters: JSON.stringify(cFilters) } : cFilters),
    ...overwrite
  }
}

// This function converts a deeply grouped data query result to a flat list of objects
export const convertNestedDataQueryResultToFlatObjects = (data, fieldNames) => {
  const result = []

  const uniqueFieldNames = ensureUniqueValues(fieldNames)

  function recursiveFlatten (obj, keys = []) {
    if (isPlainObject(obj)) {
      forOwn(obj, (value, key) => {
        recursiveFlatten(value, [...keys, key])
      })
    } else if (Array.isArray(obj)) {
      const lastField = uniqueFieldNames[keys.length]
      if (Array.isArray(lastField)) {
        // Assign array values into corresponding field names from the array

        const clonedObj = [...obj]
        if (lastField.length > clonedObj.length) {
          // the same value has to be put in all fields
          // duplicate the last value of the obj array until it's length matches the lastField array
          clonedObj.push(...Array(lastField.length - clonedObj.length).fill(clonedObj[clonedObj.length - 1]))
        }

        const mappedFields = zipObject(lastField, clonedObj)
        result.push({ ...zipObject(uniqueFieldNames.slice(0, keys.length), keys), ...mappedFields })
      } else {
        // Assign the entire array as a value for the last field name
        result.push(zipObject(uniqueFieldNames, [...keys, obj]))
      }
    } else {
      result.push(zipObject(uniqueFieldNames, [...keys, obj]))
    }
  }

  recursiveFlatten(data)

  // extend with an _id by joining all fieldNames which are not an array (grouping fields)
  result.forEach(r => {
    r._id = uniqueFieldNames.filter(f => !Array.isArray(f)).map(f => r[f]).join('|')
  })

  return result
}

export const convetNestedGroupedAggregatedDataQueryResultToPivotObjects = (data, fieldNames, groupName, pivot) => {
  const k = [groupName, pivot, 'result']
  const result = []

  function recursiveFlatten (obj, keys = []) {
    if (isPlainObject(obj)) {
      // Iterate through the object's keys and values
      forOwn(obj, (value, key) => {
        recursiveFlatten(value, [...keys, key])
      })
    } else if (Array.isArray(obj)) {
      // If the value is an array, include the full array as part of the flattened object
      result.push(zipObject(k, [...keys, obj]))
    } else {
      // If the value is neither an object nor an array, append it to the flat result
      result.push(zipObject(k, [...keys, obj]))
    }
  }

  recursiveFlatten(data)

  const pivotData = []
  const grouped = groupBy(result, groupName)
  const pivotResultFields = []

  forOwn(grouped, (values, key) => {
    const pivotRow = { [groupName]: key, _id: key }

    values.forEach(value => {
      const pivotKey = value[pivot]
      value.result.forEach((result, index) => {
        const valKey = fieldNames[index]
        const pivotStr = `${pivotKey}|${valKey}`
        pivotRow[pivotStr] = result

        // add the pivot result field to the list of pivot result fields
        if (!pivotResultFields.includes(pivotStr)) {
          pivotResultFields.push(pivotStr)
        }
      })
    })
    pivotData.push(pivotRow)
  })

  return {
    data: pivotData,
    pivotResultFields
  }
}

// const createCombinedKeysObject = (fieldNames, pivotResultData) => {
//   const pivotKeys = Object.keys(pivotResultData)

//   // Generate all combinations of pivot column values
//   const combinations = pivotKeys.reduce((acc, key) => {
//     const values = pivotResultData[key]
//     if (acc.length === 0) {
//       return values
//     }
//     return acc.flatMap(a => values.map(v => `${a}|${v}`))
//   }, [])

//   // Build the final object with fieldNames
//   const result = {}
//   combinations.forEach(combination => {
//     fieldNames.forEach(field => {
//       result[`${combination}|${field}`] = null
//     })
//   })

//   return result
// }

// const flattenPivotDataColumns = (data, fieldNames, path = []) => {
//   const result = {}

//   if (Array.isArray(data)) {
//     // Base case: when we reach an array, map field names to values
//     data.forEach((value, index) => {
//       result[[...path, fieldNames[index]].join('|')] = value
//     })
//   } else if (typeof data === 'object' && data !== null) {
//     // Recursively handle objects
//     Object.entries(data).forEach(([key, value]) => {
//       Object.assign(result, flattenPivotDataColumns(value, fieldNames, [...path, key]))
//     })
//   }

//   return result
// }

/**
 * // Example usage:
const data = {
  'Tellerrückläufe ZV': {
    'Wieland Werke AG (927500)': [9.174, 527],
    'BLG, Bremerhaven (750216)': [0, 900]
  },
  Küchenabfälle: {
    'EKD, Hannover (734700)': [23.202, 1266]
  }
}

const fieldNames = ['category__name', 'sales_location__name', 'value']
 */
// const convertNestedDataQueryResultWithValueArrayToFlatObjects = (data, fieldNames) => {
//   const result = []

//   function recursiveFlatten (obj, keys = []) {
//     if (isPlainObject(obj)) {
//       // Iterate through the object's keys and values
//       forOwn(obj, (value, key) => {
//         recursiveFlatten(value, [...keys, key])
//       })
//     } else if (Array.isArray(obj)) {
//       // If the value is an array, include the full array as part of the flattened object
//       result.push(zipObject(fieldNames, [...keys, obj]))
//     } else {
//       // If the value is neither an object nor an array, append it to the flat result
//       result.push(zipObject(fieldNames, [...keys, obj]))
//     }
//   }

//   recursiveFlatten(data)
//   return result
// }

/** Heavily work in progress for edge case when grouping and pivoting by the same column
 * - we have the flat pivot columns data, we have to get a flat list with the value, then we have to
 *   interate through it and take the values from the pivot data, resulting in a diagonal data structure
 * - alternatively we ditch some code again and find a way to restrict grouping and pivoting by the same column
 */
export const convertNestedGroupedAggregatedDataToPivotObjects = (data, fieldNames, groupName, pivotResultData) => {
  // const flatPivotColumns = flattenPivotDataColumns(pivotResultData, fieldNames)
  // const flatResults = convertNestedDataQueryResultWithValueArrayToFlatObjects(data, [groupName, 'value'])

  // function recursiveFlatten (obj, resolvedKeys = []) {
  //   if (isPlainObject(obj)) {
  //     forOwn(obj, (value, key) => {
  //       recursiveFlatten(value, [...resolvedKeys, key])
  //     })
  //   } else {
  //     const o = {
  //       ...zipObject(keys, [...resolvedKeys, obj])
  //     }
  //     // const pivotKey = pivotArray.map(p => o[p] || '').join('|')
  //     console.log('having Obj', o)
  //     // fieldNames.forEach((fieldName, index) => {
  //     //   const k = `${pivotKey}|${fieldName}`
  //     //   o[k] = o.result[index]
  //     // })

  //     flatResults.push(o)
  //   }
  // }

  const pivotData = []
  // const groupedData = groupBy(flatResults, groupName)
  const pivotResultFields = []

  // forOwn(groupedData, (groupValues, groupKey) => {
  //   const pivotRow = { [groupName]: groupKey, _id: groupKey }

  //   groupValues.forEach(value => {
  //     const pivotKey = pivotArray.map(p => value[p] || '').join('|')
  //     value.result.forEach((result, index) => {
  //       const valueKey = fieldNames[index]
  //       const pivotString = `${pivotKey}|${valueKey}`
  //       pivotRow[pivotString] = result

  //       if (!pivotResultFields.includes(pivotString)) {
  //         pivotResultFields.push(pivotString)
  //       }
  //     })
  //   })

  //   pivotData.push(pivotRow)
  // })

  return {
    data: pivotData,
    pivotResultFields
  }
}

// This function converts a grouped data with arrays of aggregated data into a flat list of objects
export const convertGroupedAggregatedDataQueryResultToFlatObjects = (data, fieldNames, groupName, pivot) => {
  const x = map(data, (values, groupKey) => {
    const aggregatedResult = { [groupName]: groupKey, _id: groupKey }
    fieldNames.forEach((fieldName, index) => {
      aggregatedResult[fieldName] = values[index]
    })
    return aggregatedResult
  })
  return x
}
