import { create } from 'zustand'

export const useAmplitudeStore = create((set) => ({
  enabled: false,
  vars: {},
  setEnabled: (enabled) => set(() => ({ enabled }))
}))

export const setAmplitudeVars = (newVars) => {
  useAmplitudeStore.setState((state) => ({
    vars: {
      ...state.vars,
      ...newVars
    }
  }))
}
