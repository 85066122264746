import { SearchSelect } from 'components/SearchSelect'
import useDemo from 'hooks/useDemo'
import { useIntl } from 'react-intl'
import { useFoodwasteCategories, useOwnFoodwasteConfigs } from 'hooks/queries'
import labelMessages from 'components/labelMessages'
import { useMemo } from 'react'
import { usePermissions } from 'hooks'
import { groupBy } from 'lodash'
import { useAmplitudePickerLoadingPerformance } from 'components/AppCore/Amplitude'

export default ({ location, range, value, onChange, onlyOwn, gray, type, withPortal, disabled, onValidationChange }) => {
  const intl = useIntl()
  const { demo } = useDemo()
  const { isSuperUser } = usePermissions()

  const { data, status, isFetching, fetchStatus } = useFoodwasteCategories({
    ...(location ? { location } : {}),
    ...(range ? { range } : {})
  })
  useAmplitudePickerLoadingPerformance('Waste Categories', fetchStatus, { Locations: location, 'Date Range': range })

  const { data: ownFW } = useOwnFoodwasteConfigs({ enabled: !isSuperUser && onlyOwn })

  const allowedCategories = useMemo(() => {
    if (isSuperUser || !onlyOwn || !ownFW) return []
    const groups = groupBy(ownFW, 'category')
    return Object.keys(groups).map(i => parseInt(i, 10))
  }, [ownFW, onlyOwn])

  const filteredData = useMemo(() => {
    if (isSuperUser || !onlyOwn || !data) return data
    return data.filter((item) => allowedCategories.includes(item.id))
  }, [data, allowedCategories, onlyOwn])

  if ((status === 'pending' && fetchStatus === 'idle') || (status === 'success' && filteredData && filteredData.length === 0)) { return null }

  return (
    <SearchSelect
      role='foodwaste-category-picker'
      type={type || 'round'}
      size='small'
      placeholder={intl.formatMessage(labelMessages.wasteCategory)}
      value={value}
      onChange={onChange}
      onValidationChange={onValidationChange}
      loading={isFetching}
      optionsLabel={intl.formatMessage(labelMessages.wasteCategories)}
      options={filteredData && filteredData.map((item) => ({
        key: item.id,
        label: demo(item.name)
      }))}
      withPortal={withPortal}
      graybg={gray}
      disabled={disabled}
    />
  )
}
